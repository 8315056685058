body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.profile-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(180deg, #086BCC 0%, #0A1D44 100%);
  color: white; /* Ensure the text inside the profile page is white */
  padding: 20px;
}

.burger-menu {
  color: white; /* Set the color to white for the hamburger menu */
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 10px 20px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  height: 70px; /* Add height to the header to ensure content fits */
}

.header-left {
  flex: 1; /* This will take up space to push the logo to the center */
}

.header-right {
  flex: 1; /* This will take up space to push the logo to the center */
  display: flex;
  justify-content: flex-end; /* Align items to the right */
  align-items: center;
}

.header .logo {
  height: 50px;
  position: absolute;
  top: 50%;
  left: 49%;
  transform: translate(-50%, -50%);
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.welcome-message {
  color: white; /* Ensure welcome message is white */
  font-weight: bold;
  margin-right: 10px;
}

.dropdown-menu {
  position: absolute;
  top: 60px;
  right: 20px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  z-index: 1001;
}

.dropdown-menu p {
  margin: 10px 0;
  cursor: pointer;
  color: #0C69C8; /* This could remain blue as desired */
}

.profile-header {
  text-align: center;
  margin-bottom: 30px;
  margin-top: 120px; /* Add margin-top to create space from the logo */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-header input[type="file"] {
  margin: 10px 0;
}

.profile-header h1, .profile-header h2 {
  color: white; /* Ensure the headers in the profile header are white */
  margin: 10px 0 5px 0;
}

.profile-img {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  object-fit: cover;
  margin-bottom: 20px;
}

.file-input-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 10px 0;
}

.profile-section {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 600px;
  color: white; /* Ensure the text inside profile sections is white */
}

.profile-section h3 {
  border-bottom: 2px solid #D5A419;
  padding-bottom: 10px;
  margin-bottom: 10px;
  color: white; /* Ensure section headers are white */
}

.profile-section p, .profile-section ul {
  color: white; /* Ensure paragraph and list text are white */
  margin: 10px 0;
}

.profile-section ul {
  list-style-type: none;
  padding: 0;
}

.profile-section li {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  color: white; /* Ensure list items are white */
}

.profile-section li:hover {
  background: rgba(255, 255, 255, 0.3);
}

.edit-btn, .update-btn {
  background-color: #0A1D44;
  color: white; /* Ensure button text is white */
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.edit-btn:hover, .update-btn:hover {
  background-color: #086BCC;
}

.edit-btn:active, .update-btn:active {
  background-color: #064B8A;
}

.edit-btn {
  margin-left: 10px;
}

.update-btn {
  margin-left: 10px;
  margin-top: 10px;
}

.sport-details {
  margin-top: 10px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  padding: 10px;
}

.sport-details p {
  color: white; /* Ensure sport details text is white */
  margin: 5px 0;
}

.team-details {
  margin-top: 10px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  padding: 10px;
}

.team-details p {
  color: white; /* Ensure team details text is white */
  margin: 5px 0;
}

.file-input-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* This will center the file input horizontally */
  justify-content: center;
  margin-top: 20px; /* Adjust margin as necessary */
}

#fileInput {
  margin-bottom: 10px; /* Adds space between the file input and text */
}

.upload-instruction {
  color: white; /* Optional: Adjust the color to match your design */
  font-size: 14px; /* Adjust font size as needed */
  margin-top: 5px;
}
.custom-file-upload {
  display: inline-block;
  padding: 10px 20px;
  cursor: pointer;
  background-color: #0A1D44;
  color: white;
  border-radius: 5px;
  font-size: 14px;
  transition: background-color 0.3s ease, transform 0.3s ease; /* Transition for color and size */
}

.custom-file-upload:hover {
  background-color: #D5A419; /* Change to a lighter blue on hover */
  transform: scale(1.05); /* Slightly increase size on hover */
}

#fileName {
  margin-top: 10px;
  color: white;
  font-size: 14px;
}
