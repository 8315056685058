/* Global styles in App.css or similar */

body, html {
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* Component-specific styles */
.terms-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin: 0;
    width: 100%; /* Ensure it takes up the full width of the screen */
    box-sizing: border-box; /* Include padding and border in element's total width and height */
}

.content {
    padding: 20px;
    max-width: 800px;
    width: 100%;
    margin-top: 20px;
    box-sizing: border-box; /* Include padding and border in element's total width */
}

section {
    margin-bottom: 20px;
    width: 100%; /* Ensure sections take up full width */
}

h3, p {
    word-wrap: break-word; /* Ensure text wraps and doesn't cause horizontal scroll */
}
