/* styles/Support.css */

.support-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin: 0;
}

.logo {
    height: 50px; /* Adjust as necessary */
    margin-top: 20px;
}

.update-info {
    margin-top: 10px;
    font-size: 1em;
    color: #666;
    text-align: center;
}

.content {
    padding: 20px;
    max-width: 800px;
    width: 100%;
    margin-top: 20px;
}

section {
    margin-bottom: 20px;
}


h3 {
    font-size: 1.5em;
    color: #333;
    margin-bottom: 10px;
}

p, ul {
    font-size: 1em;
    line-height: 1.6em;
    color: #555;
}

ul {
    margin-left: 20px;
    list-style-type: disc;
}

ul li {
    margin-bottom: 10px;
}

/* Contact Support Section */
.contact-info {
    margin-top: 20px;
    text-align: center;
    color: #333;
}

.contact-info a {
    color: #3498db;
    text-decoration: none;
}

.contact-info a:hover {
    text-decoration: underline;
}

/* Email Button */
.email-button {
    background-color: #3498db;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    margin: 20px 0;
}

.email-button:hover {
    background-color: #2980b9;
}

/* FAQ Section */
.faq-section {
    margin-top: 30px;
}

.feedback-section {
    margin-top: 40px;
    text-align: center;
}


textarea.feedback-textarea {
    width: 100%;
    height: 120px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-bottom: 20px;
    font-size: 1em;
}

.submit-button {
    background-color: #3498db;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
}

.submit-button:hover {
    background-color: #3498db;
}

/* Success Message */
.success-message {
    color: #3498db;
    font-size: 1.1em;
    margin-top: 15px;
}
