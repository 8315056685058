/* styles/PrivacyPolicy.css */

.privacy-policy-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin: 0;
}

.logo {
    height: 50px; /* Adjust as necessary */
}

.update-info {
    margin-top: 10px;
    font-size: 1em;
    color: #666;
}

.content {
    padding: 20px;
    max-width: 800px;
    width: 100%;
    margin-top: 20px;
}

section {
    margin-bottom: 20px;
}

h3 {
    font-size: 1.5em;
    color: #333;
}

p {
    font-size: 1em;
    line-height: 1.6em;
    color: #555;
}
