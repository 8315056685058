/* LaunchPage.css */

.header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 10px 20px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

.launch-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(180deg, #086BCC 0%, #0A1D44 100%);
  padding-top: 60px; /* To prevent overlap with header */
}

.header img.logo {
  height: 50px;
  position: absolute;
  top: 40%;
  left: 48.5%;
  transform: translate(-50%, -50%);
}
@media (max-width: 430px) {
  .header img.logo {
    left: 46%;
  }

  .burger-menu {
    margin-right: 20px;
  }

  .modal-content {
    width: 90%; /* Adjust the width to be smaller */
    max-width: 300px; /* Set a maximum width suitable for small screens */
    padding: 15px; /* Reduce padding for smaller screens */
  }

  .modal-content form input,
  .modal-content form button {
    width: calc(100% - 20px); /* Adjust width to fit within the modal */
    margin: 8px 10px; /* Reduce margin for smaller screens */
    padding: 8px; /* Adjust padding to be smaller */
  }

  .sign-in-text {
    margin-right: 30px;
    margin-top: 20px;
  }

  .welcome-message {
   font-size: 11px;
  }
  
}




.header-right {
  position: absolute;
  right: 20px;
  display: flex;
  align-items: center;
}

.sign-in-text {
  cursor: pointer;
  color: #0C69C8;
  font-weight: bold;
  margin-right: 60px;
  margin-top: 20px;

}

.user-controls {
  display: flex;
  align-items: center;
  position: relative;
}

.welcome-message {
  color: #0A1D44 !important;
  margin-right: 10px;
  margin-top: 26px;
}



.burger-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-right: 40px;
  margin-top: 30px;
  border: 1px solid #0C69C8; /* Set border color to solid blue */
  border-radius: 4px;
  cursor: pointer;
  background-color: #0C69C8; /* Set background color to solid blue */
  color: white; /* Set icon or text color to white */
  transition: background-color 0.3s, border-color 0.3s;
}



/* Show drawer on hover */
.burger-menu:hover .drawer-menu,
.drawer-menu.show {
  opacity: 1;
  visibility: visible;
}

.drawer-menu-item {
  padding: 10px;
  cursor: pointer;
}

.drawer-menu-item:hover {
  background-color: #f0f0f0;
}


.burger-menu:hover {
  background-color: rgba(0, 0, 0, 0.1);
  border-color: rgba(0, 0, 0, 0.2);
}

.burger-menu:active {
  background-color: rgba(0, 0, 0, 0.2);
}

.dropdown-menu {
  position: absolute;
  margin-top: 10px;
  margin-right:17px;

  top: 40px;
  right: 20;
  background-color: white;
  color: black;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1002;
  text-align: left;
  padding: 10px;
  width: 150px;
}

.dropdown-item {
  padding: 10px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}



.video-container {
  position: relative;
  width: 100%;
  height: 90vh; /* Adjust the height to give space for other components */
  overflow: hidden;
  z-index: 0;
  margin-top: 10px; /* Adjust to prevent overlap with header */
}

video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 20px; /* Adjust this if necessary to avoid overlap */
  color: white;
}

.beta-signup {
  text-align: center;
}

.beta-signup form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.beta-signup input,
.beta-signup select {
  padding: 10px;
  margin-bottom: 20px; /* Adjust to ensure space between elements */
  width: 300px;
  max-width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
}
@media (max-width: 768px) {
  .footer .store-button {
    width: 120px; 
    height: auto;
  }
}

.video-container video {
  transition: opacity 0.2s ease-in-out; /* Match with timeout delay */
}

.video-container video.fade-out {
  opacity: 0;
}

.video-container video.fade-in {
  opacity: 1;
}

.beta-signup button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #D5A419;
  color: white;
  cursor: pointer;
}


.beta-signup button:hover {
  background-color: #0A1D44;
}

.footer {
  width: 100%;
  text-align: center;
  background: #ffff 100%;
  padding: 20px 0;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto; /* Ensures the footer stays at the bottom */
}

.footer h2 {
  margin-bottom: 10px;
  color: #0A1D44;
  font-size: 30px;
}

.footer .buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 10px;
}


.footer .store-button {
  width: 150px; 
  height: auto;
  max-width: 100%;
}


.footer .links {
  display: flex;
  justify-content: center;
  gap: 140px;
  margin-top: 10px;
}

.footer .links a {
  color: #0A1D44;
  text-decoration: none;
}

.footer .links a:hover {
  text-decoration: underline;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.social-icons a {
  color: #0A1D44;
  font-size: 24px;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1001;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  position: relative;
  width: 400px;
}

.modal-content form {
  width: 100%; /* Ensure form takes full width of modal */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center inputs and button horizontally */
}

.modal-content input,
.modal-content button,
.modal-content textarea {

  font-size: 18px;
}



.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  color: #000; /* Add color for visibility */
}

.password-container {
  display: flex;
  align-items: center; /* Vertically center the input and the icon */
  justify-content: center; /* Center the container horizontally */
  width: 105.5%; /* Make it take full width, just like the inputs */
  max-width: 500px; /* Same max-width as the other inputs */
  position: relative; /* To position the eye icon inside the container */
}






.password-container .eye-icon {
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
  cursor: pointer;
}

.modal-logo {
  height: 50px;
  margin-bottom: 20px;
}

.modal-content form input,
.modal-content form button {
  width: calc(100% - 40px);
  margin: 10px 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.modal-content form button {
  background-color: #0C69C8;
  color: white;
  cursor: pointer;
}


.modal-content form button:hover {
  background-color: #094A92;
}

.spline-container {
  width: 100%;
  height: 60vh; /* Adjust this value to fit the content better */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .content-section {
    flex-direction: column;
    text-align: center;
  }

  .content-section img {
    width: 100%;
    height: auto;
    margin: 10px 0;
  }

  .footer .links {
    flex-direction: column;
    gap: 10px;
  }

  .footer .buttons {
    flex-direction: column;
    gap: 10px;
  }

  .footer .store-button {
    width: 200px;
    height: auto;
  }
}












